<script setup>
const emit = defineEmits(['showLoginPanel'])
const localePath = useLocalePath()
const router = useRouter()
const {$isInMobileAppWebView} = useNuxtApp()
const {postMessageMyProfile, postMessageLogin} = usePostMessage()

const auth = useAuthStore()

const logOut = async () => {
  await auth.logout()
  router.replace(localePath('/'))
}

const anonymousClick = () => {
  if ($isInMobileAppWebView.value) {
    postMessageLogin()
  } else {
    emit('showLoginPanel')
  }
}

const handleMyPreference = () => {
  if ($isInMobileAppWebView.value) {
    postMessageMyProfile()
  } else {
    router.push(localePath({name: 'profile-my-preferences'}))
  }
}
</script>

<style lang="scss" scoped>
@import 'assets/css/components/global/nu-header-profile.css';
</style>

<template>
  <div class="nu-header-profile">
    <template v-if="auth.isLoggedIn">
      <a
        id="profile-dropdown"
        aria-expanded="false"
        class="dropdown-handler"
        data-bs-toggle="dropdown"
        role="button"
      >
        <img
          alt="user"
          class="user-icon"
          src="/images/common/user.svg"
          width="18"
        />
        <img
          alt="user"
          class="user-icon-active"
          src="/images/common/user-active.svg"
          width="18"
        />
      </a>

      <ul
        aria-labelledby="profile-dropdown"
        class="dropdown-menu"
      >
        <li
          class="user-details"
          v-if="auth && auth.user"
        >
          <p>
            {{ auth.user.name }}
          </p>
          <p>{{ auth.user.email }}</p>
        </li>
        <li>
          <NuxtLink
            class="dropdown-item"
            @click="handleMyPreference"
          >
            {{ $t('route.my_preferences') }}
          </NuxtLink>
          <font-awesome-icon :icon="['fas', 'chevron-right']" />
        </li>
        <li>
          <NuxtLink
            :to="localePath({path: '/profile/change-password'})"
            class="dropdown-item"
          >
            {{ $t('route.change_password') }}
          </NuxtLink>
          <font-awesome-icon :icon="['fas', 'chevron-right']" />
        </li>
        <li>
          <NuxtLink
            :to="localePath({path: '/profile/close-account'})"
            class="dropdown-item"
          >
            {{ $t('route.close_account') }}
          </NuxtLink>
          <font-awesome-icon :icon="['fas', 'chevron-right']" />
        </li>
        <li>
          <a
            class="dropdown-item"
            href="#"
            @click="logOut"
          >
            {{ $t('comp_nu_header_profile.logout') }}
          </a>
          <font-awesome-icon :icon="['fas', 'chevron-right']" />
        </li>
      </ul>
    </template>
    <a
      class="dropdown-handler"
      href="#"
      v-else
      @click="anonymousClick"
    >
      <span class="login-text-desktop">{{ $t('comp_nu_header_profile.login_register') }}</span>
      <img
        alt="user"
        class="user-icon user-icon-mobile"
        src="/images/common/user.svg"
        width="18"
      />
    </a>
  </div>
</template>
